.swal-custom-button {
   width: 100% !important;
   color: rgba(10, 115, 121, 1) !important;
   /* replace with your primary text color */
   background-color: rgba(237, 233, 71, 1) !important;
   /* replace with your secondary background color */
   border-radius: 50px !important;
   padding: 0.6rem !important;
   padding-left: 1.2rem !important;
   padding-right: 1.2rem !important;
   font-family: Montserrat !important;
   font-size: 15px !important;
   font-weight: 500 !important;
   box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1) !important;
   transition: all 0.3s cubic-bezier(.25, .8, .25, 1) !important;
}

/* Hover Styles */
.swal-custom-button:hover {
   color: rgba(237, 233, 71, 1) !important;
   /* replace with your hover text color */
   background-color: rgba(10, 115, 121, 1) !important;
   /* replace with your hover background color */
}


.swal-frame {
   border-radius: 50px !important;
}

.swal-text {
   color: rgba(10, 115, 121, 1) !important;
   font-weight: 400 !important;
}

.swal-title {
   font-size: 26px !important;
   color: rgba(10, 115, 121, 1) !important;
   font-weight: 700 !important;
}