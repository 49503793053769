.slider-security {
  width: 100%;
  height: auto;
  margin: auto auto 6rem auto;
  overflow: hidden;
}

.slider-security .slide-track-security {
  display: flex;
  animation: scroll 30s linear;
  -webkit-animation: scroll 30s linear infinite;
  width: calc(500px * 56);
}

.slider-security .slide-track-security:hover {
  animation-play-state: paused;
}

.slider-security .slide-security {
  display: flex;
  gap: 1rem;
  padding-right: 1rem;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
    -webkit-transform: translateX(0);
  }
  100% {
    transform: translateX(calc((-502px * 6)));
    -webkit-transform: translateX(calc((-502px * 6)));
  }
}
