.parallax {
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  overflow-x: hidden;
}

.parallax_container {
  overscroll-behavior: none;
  width: 400%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background: linear-gradient(
    180deg,
    rgba(255, 231, 204, 0) 0%,
    #ffe7cc 31.25%
  );
}

.panel_parallax {
  width: 100%;
  height: 90vh;
}

@media (max-width: 768px) {
  .parallax_container {
    flex-direction: column;
  }

  .panel_parallax {
    height: 100vh;
  }
}
